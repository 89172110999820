<template>
    <div
        :class="containerClasses"
        style="height: 90vh; overflow-y: scroll"
    >
        <div
            v-if="!isLoading"
            class="row"
        >
            <div class="col-12">
                <div
                    id="scrollTarget"
                    class="row col-12 form-details"
                    style="font-size: 23px"
                >
                    <div class="form-title">
                        <span>{{ selectedRule.title }}</span>
                    </div>

                    <div class="col">
                        <span>Modified by {{ versions.latest.updatedBy.username }} {{ latestVersionUpdatedAt }}</span>
                    </div>
                </div>

                <MergeProcess
                    :versions="versions"
                    :json-fields="['data']"
                    :page-map="{expressionGroups: '2'}"
                    :readable-text-map="mergeTableReadableText"
                    :added-object-update-keys="['groupId']"
                    :related-keys="{'componentType': ['data'], 'actionType': ['data', 'errorMessage']}"
                    :exclude-keys="['versionId', 'groupId', 'parentId', 'parent', 'expressionId', 'position']"
                    @save="saveMerge"
                    @cancel-merge="returnToRuleOptions"
                    @confirm-modal-closed="SET_EXPRESSION_BUILDER_DATA({field: 'expressionGroups', data: []});"
                >
                    <template #confirm-modal-body>
                        <BRAddEditForm
                            ref="AddEditForm"
                            class="stickycontent"
                            read-only
                            :selected-rule="selectedRule"
                            :selected-version="selectedVersion"
                            :tooltip-wording="tooltipWording"
                        />
                    </template>
                </MergeProcess>
            </div>
        </div>

        <div v-else>
            <div class="loading d-flex justify-content-center align-content-center">
                <div class="content text-center my-auto">
                    <FontAwesomeIcon
                        icon="spinner"
                        pulse
                    />

                    <h5>loading...</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils, {deepCase, stripIds} from '@imt/vue-toolbox/src/utils';
    import {formatted} from '@imt/vue-toolbox/src/utils/date';
    import MergeProcess from '@imt/vue-versioning/src/components/MergeProcess.vue';
    import {mapActions, mapMutations, mapState} from 'vuex';

    import BRAddEditForm from '@/components/BRAddEditForm.vue';
    import {mergeTableReadableText, tooltips} from '@/lang';

    // @group Views
    export default {
        name: 'BRMerge',
        components: {
            BRAddEditForm,
            MergeProcess,
        },
        mixins: [
            toastsMixin,
        ],
        data() {
            return {
                allDiffObject: {},
                isLoading: true,
                tooltipWording: tooltips.en,
                mergeTableReadableText,
                selectedRule: {},
                selectedVersion: {},
                versions: {
                    base: null,
                    draft: null,
                    latest: null,
                },
            };
        },
        computed: {
            containerClasses() {
                return {
                    'container-fluid': true,
                    'menu-shown': !this.sideMenuHidden,
                    'menu-collapsed': !this.sideMenuHidden && this.sideMenuCollapsed,
                    'menu-expanded': !this.sideMenuHidden && !this.sideMenuCollapsed,
                };
            },
            latestVersionUpdatedAt() {
                return this.formatDateTime(this.versions.latest.updatedAt, 'MM/DD/YYYY [at] h:mm a');
            },
            ...mapState(['rules']),
            ...mapState(['currentPolicySystem']),
            ...mapState('toolbox', [
                'sideMenuCollapsed',
                'sideMenuHidden',
            ]),
            ...mapState('vueVersioning', [
                'combinedVersion',
                'diffs',
                'diffPage',
                'foundDifferences',
            ]),
        },
        watch: {
            diffPage() {
                // scroll to top of merge table when the page changes
                document.getElementById('scrollTarget') ?
                    document.getElementById('scrollTarget').scrollIntoView() :
                    null;
            },
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (!this.foundDifferences) {
                    this.$router.replace({query: {
                        rule_id: this.$route.params.ruleId,
                        draft: this.$route.query.draft,
                        latest: this.$route.query.latest,
                        base: this.$route.query.base,
                    }});
                }

                try {
                    if (!this.selectedRule?.id) {
                        this.selectedRule = await this.fetchRule(this.$route.params.ruleId);
                        this.selectedVersion = await this.fetchVersionDetails(this.$route.params.versionId);
                    }

                    // Fetch the base version.
                    this.versions.base = await this.fetchVersionDetails(this.$route.query.base);

                    for (let versionType of ['draft', 'latest']) {
                        // Fetch the draft version and latest published version.
                        this.versions[versionType] = await this.fetchVersionDetails(this.$route.query[versionType]);

                        // Fetch the differences between the draft version and the base, and the latest published version and the base.
                        if (!this.foundDifferences) {
                            const diffs = await this.fetchDiffs({
                                oldVersionId: this.$route.query.base,
                                newVersionId: this.$route.query[versionType],
                            });

                            this.SET_DIFFS({
                                diffs,
                                versionType,
                            });
                        }
                    }
                } catch (error) /* istanbul ignore next */ {
                    utils.console.log(error);
                    this.error('Failed to fetch rule version differences!');
                } finally {
                    this.isLoading = false;
                }
            },
            returnToRuleOptions() {
                this.$ga.event('Merge View', 'Return to List View');
                // reset the diff page and foundDifferences, then close the confirm merge modal after saving
                this.RESET_MERGE_STATE();
                this.$root.$emit(`bv::toggle::modal`, 'confirmMerge');

                this.$router.push({
                    name: 'admin.rules.options',
                    params: {
                        ruleId: this.selectedRule.id,
                        policySystemId: this.currentPolicySystem.id
                    },
                });
            },
            async saveMerge(asNew) {
                this.$ga.event('Merge View', 'Save Merge', 'action');

                if (asNew) {
                    try {
                        // Create a new draft (the combinedVersion's id will be the id of the previous draft)
                        let newDraft = await this.createDraft(this.combinedVersion.id);

                        // Update new draft's data
                        await this.updateDraft({
                            ...this.combinedVersion,
                            id: newDraft.id,
                        });
                    } catch (error) /* istanbul ignore next */ {
                        utils.console.log(error);
                        this.error('Error creating new draft!');
                    }
                } else {
                    try {
                        await this.updateDraft(this.combinedVersion);
                    } catch (error) /* istanbul ignore next */ {
                        utils.console.log(error);
                        this.error('Error updating draft!');
                    }
                }
            },
            async updateDraft(version) {
                try {
                    let updatedDraft = await this.updateVersion({
                        versionId: version.id,
                        data: deepCase({
                            id: version.id,
                            type: 'Version',
                            ...stripIds({
                                actions: version.actions,
                                category: version.category,
                                condition: version.condition,
                                description: version.description,
                                draftOfId: this.versions.latest.id,
                                internalNotes: version.internalNotes,
                                helpWordingText: version.helpWordingText,
                                transactionType: version.transactionType,
                                expressionGroups: version.expressionGroups,
                            }),
                        }, 'snake', ['dataType']),
                    });

                    if (updatedDraft) {
                        this.returnToRuleOptions();
                    }
                } catch (error) /* istanbul ignore next */ {
                    utils.console.log(error);
                    this.error('Error updating draft!');
                }
            },
            formatDateTime: formatted,
            ...mapActions([
                'createDraft',
                'fetchDiffs',
                'fetchRule',
                'fetchVersionDetails',
                'updateVersion',
            ]),
            ...mapMutations('expressionBuilder', [
                'SET_EXPRESSION_BUILDER_DATA',
            ]),
            ...mapMutations('vueVersioning', [
                'RESET_MERGE_STATE',
                'SET_DIFFS',
            ]),
        },
    };
</script>
