<template>
    <div
        :id="`component${component.syncId || component.id}`"
        class="expression-component highlight-container"
        :class="{'col-lg-4': !parentSubType, 'col-12': !parentSubType}"
        :data-cy-id="`component${component.position}`"
        :data-id="component.syncId || component.id"
        data-type="components"
    >
        <template
            v-for="(dropdown, dropdownIndex) in componentDropdowns"
        >
            <BRComponentDropdown
                v-if="!dropdown.isHidden"
                :key="dropdown.key"
                ref="dropdowns"
                :config-arguments="dropdownConfigOptions"
                :index="dropdownIndex"
                :parent-id="component.id"
                parent-type="component"
                :read-only="readOnly"
                v-bind="dropdown.props"
                v-on="dropdown.events"
            />
        </template>

        <BRLiteralValue
            v-if="showConditionDataFields"
            ref="dateUnits"
            :initial-value="component.data.units"
            label="Units"
            literal-type="single"
            :read-only="readOnly"
        />

        <BRLiteralValue
            v-if="subType && component.componentType === 'literal'"
            ref="literalValue"
            :initial-value="component.data.value"
            :literal-type="subType"
            :read-only="readOnly"
        />

        <div v-else-if="component.componentType === 'method'">
            <BRExpressionComponent
                v-for="child in orderedChildren"
                :key="child.id"
                ref="childComponents"
                :component="child"
                :parent-id="component.id"
                :parent-sub-type="subType"
                :read-only="readOnly"
            />
        </div>
    </div>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep';
    import orderBy from 'lodash/orderBy';
    import {mapMutations, mapState} from 'vuex';

    import {generateId, getId} from '@/utilities';

    import BRComponentDropdown from './BRComponentDropdown.vue';
    import BRLiteralValue from './BRLiteralValue.vue';

    // @group ExpressionBuilder
    export default {
        name: 'BRExpressionComponent',
        components: {
            BRComponentDropdown,
            BRLiteralValue,
        },
        props: {
            // The component object
            component: {
                type: Object,
                required: true,
            },
            // The id of the parent component, if any
            parentId: {
                type: [String, Number],
                default: '',
            },
            // The sub-type of the parent component, if any
            parentSubType: {
                type: String,
                default: '',
            },
            // Indicates if the component is in read-only mode
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                subType: null,
            };
        },
        computed: {
            componentDropdowns() {
                return this.dropdowns.component[this.component.id] || [];
            },
            dropdownConfigOptions() {
                return {
                    component: this.component,
                    expressionId: this.component.expressionId,
                    isRightHand: this.isRightHand,
                    leftHandComponents: this.leftHandComponents,
                    parentSubType: this.parentSubType,
                };
            },
            isRightHand() {
                return this.component.position === 2 && !this.parentId;
            },
            orderedChildren() {
                return orderBy(this.component.children, 'position');
            },
            showConditionDataFields() {
                let conditionDataFields = [
                    'date_is_more_than_units_after',
                    'date_is_less_than_units_after',
                    'date_is_more_than_units_before',
                    'date_is_less_than_units_before',
                ];

                return this.component.componentType === 'condition' && conditionDataFields.includes(this.subType);
            },
            ...mapState('expressionBuilder', [
                'components',
                'dropdowns',
                'leftHandComponents',
                'linkedPageData',
            ]),
        },
        created() {
            this.init();
        },
        methods: {
            changeComponentType({value}) {
                if (value === this.component.componentType) {
                    return;
                }

                this.$ga.event('Expression Component', 'Change Component Type', 'action');
                let args = {
                    component: this.component,
                    componentType: value,
                    data: {},
                    parentId: this.parentId,
                };

                this.subType = null;

                if (value !== 'method' && this.orderedChildren.length) {
                    // Only method components should have child components.
                    args.children = [];
                } else if (value === 'literal') {
                    this.changeLiteralType({value: 'single'});
                }

                this.SET_COMPONENT(args);
                this.$nextTick(() => {
                    this.setDropdowns();
                });
            },
            changeConditionType({value}) {
                if (value === this.subType) {
                    return;
                }

                this.$ga.event('Expression Component', 'Change Condition Type', 'action');
                this.subType = value;
                this.SET_COMPONENT({
                    parentId: this.parentId,
                    component: this.component,
                    data: {
                        ...this.component.data,
                        type: value,
                    },
                });

                this.setConditionDropdowns();

                this.$emit('condition-changed', {
                    component: this.component,
                    subType: this.subType,
                });
            },
            changeLiteralType({value}) {
                this.$ga.event('Expression Component', 'Change Literal Type', 'action');
                this.subType = value;
            },
            changeMethodType({forceUpdate = false, value}) {
                if (value === this.subType && !forceUpdate) {
                    return;
                }

                this.$ga.event('Expression', 'Change Method Type', 'action');

                // User is changing data type - refresh child components.
                let parent = cloneDeep(this.component);

                if (value === 'math') {
                    // Mathematical methods are in the format <component> <operator> <component>
                    this.$emit('children-updated', [
                        {
                            componentType: 'object',
                            data: {},
                            expressionId: this.component.expressionId,
                            id: generateId(),
                            parent,
                            position: 0,
                        },
                        {
                            componentType: 'operator',
                            data: {},
                            expressionId: this.component.expressionId,
                            id: generateId(),
                            parent,
                            position: 1,
                        },
                        {
                            componentType: 'object',
                            data: {},
                            expressionId: this.component.expressionId,
                            id: generateId(),
                            parent,
                            position: 2,
                        },
                    ]);
                } else {
                    // Aggregate methods are in the format <components>
                    this.$emit('children-updated', [{
                        componentType: 'object',
                        data: {},
                        expressionId: this.component.expressionId,
                        id: generateId(),
                        parent,
                        position: 0,
                    }]);
                }

                this.subType = value;
            },
            changeObjectType({value}) {
                if (value === this.subType) {
                    return;
                }

                this.subType = value;
                this.SET_COMPONENT({
                    component: this.component,
                    parentId: this.parentId,
                    data: {},
                });

                this.setObjectDropdowns();
            },
            changeOperatorType({value}) {
                this.subType = value;
            },
            changeVersion({value}) {
                this.SET_COMPONENT({
                    parentId: this.parentId,
                    component: this.component,
                    data: {
                        ...this.component.data,
                        version: value || 'current_version',
                    },
                });
            },
            compileForSave() {
                let children = [],
                    data = {dataType: this.subType},
                    id = getId(this.component);

                if (this.component.componentType === 'object') {
                    data.dataType = this.subType;

                    if (this.component.data.version) {
                        data.version = this.component.data.version;
                    }
                } else if (this.component.componentType === 'condition') {
                    data.matchType = this.component.data.matchType;
                    data.comparisonType = this.component.data.comparisonType;

                    if (this.showConditionDataFields) {
                        data.unitType = this.component.data.unitType;
                        data.units = this.$refs.dateUnits.compileForSave().value;
                    }
                } else if (this.component.componentType === 'literal') {
                    data.value = this.$refs.literalValue.compileForSave().value;
                } else if (this.component.componentType === 'method') {
                    if (this.$refs.childComponents) {
                        for (let component of this.$refs.childComponents) {
                            children.push(component.compileForSave());
                        }
                    }
                }

                return {
                    id,
                    data,
                    children,
                    component_type: this.component.componentType,
                    position: this.component.position,
                    type: id ? 'Component' : undefined,
                };
            },
            init() {
                this.subType = this.component.data.dataType;
                this.setDropdowns();

                if (this.component.position === 0 && !this.parentId) {
                    this.SET_LEFT_HAND_COMPONENT({
                        expressionId: this.component.expressionId,
                        componentId: this.component.id,
                    });
                }

                if (this.component.componentType === 'object') {
                    this.setObjectDropdowns();
                }

                if (this.component.componentType === 'condition') {
                    this.setConditionDropdowns();
                }
            },
            setConditionDropdowns() {
                let dropdowns = this.componentDropdowns.filter(d => !d.props.conditionData);

                dropdowns.push({
                    events: {
                        'dropdown-changed': ({value}) => this.SET_COMPONENT({
                            component: this.component,
                            data: {
                                ...this.component.data,
                                comparisonType: value
                            },
                            parentId: this.parentId,
                        }),
                    },
                    props: {
                        conditionData: true,
                        initialValue: this.component.data.comparisonType,
                        name: 'comparisonType',
                    }
                });

                dropdowns.push({
                    events: {
                        'dropdown-changed': ({value}) => this.SET_COMPONENT({
                            component: this.component,
                            data: {
                                ...this.component.data,
                                matchType: value,
                            },
                            parentId: this.parentId,
                        }),
                    },
                    props: {
                        conditionData: true,
                        initialValue: this.component.data.matchType,
                        name: 'matchType',
                    }
                });

                if (this.showConditionDataFields) {
                    dropdowns.push({
                        events: {
                            'dropdown-changed': ({value}) => this.SET_COMPONENT({
                                component: this.component,
                                data: {
                                    ...this.component.data,
                                    unitType: value,
                                },
                                parentId: this.parentId,
                            }),
                        },
                        props: {
                            conditionData: true,
                            initialValue: this.component.data.unitType,
                            name: 'unitType',
                        }
                    });
                }

                this.SET_DROPDOWNS({
                    componentId: this.component.id,
                    dropdowns,
                    type: 'component',
                });
            },
            setObjectDropdowns() {
                let dropdowns = this.dropdowns.component[this.component.id] || [];

                // Add version dropdown to the component if the object type path is for a version field
                if (this.subType?.includes('version') && !dropdowns.some(dropdown => dropdown?.props?.name === 'version')) {
                    dropdowns.push({
                        events: {
                            'dropdown-changed': this.changeVersion,
                        },
                        key: generateId(),
                        props: {
                            initialValue: this.component.data.version || 'current_version',
                            name: 'version',
                        },
                    });
                }

                this.SET_DROPDOWNS({
                    componentId: this.component.id,
                    dropdowns,
                    type: 'component',
                });
            },
            setDropdowns() {
                let dropdowns = [{
                        events: {
                            'dropdown-changed': this.changeComponentType,
                        },
                        key: 'componentType',
                        props: {
                            initialValue: this.component.componentType,
                            name: 'componentType',
                            displayAsText: this.component.componentType === 'condition'
                        },
                        value: this.component.componentType,
                    }],
                    secondaryDropdownConfig = {
                        condition: {
                            name: 'conditionType',
                            onChange: this.changeConditionType,
                        },
                        literal: {
                            name: 'literalType',
                            onChange: this.changeLiteralType,
                        },
                        method: {
                            name: 'methodType',
                            onChange: this.changeMethodType,
                        },
                        object: {
                            name: 'objectType',
                            onChange: this.changeObjectType,
                        },
                        operator: {
                            name: 'mathematicalMethodType',
                            onChange: this.changeOperatorType,
                        },
                    };

                if (this.component.componentType) {
                    dropdowns.push({
                        events: {
                            'dropdown-changed': secondaryDropdownConfig[this.component.componentType].onChange,
                        },
                        key: generateId(),
                        props: {
                            initialValue: this.subType,
                            name: secondaryDropdownConfig[this.component.componentType].name,
                        },
                    });
                }

                this.SET_DROPDOWNS({
                    componentId: this.component.id,
                    dropdowns,
                    type: 'component',
                });
            },
            showChildModal(modalId) {
                // Bootstrap-Vue event. [Check their docs for full documentation](https://bootstrap-vue.js.org/)
                // @arg The id of the modal and component
                this.$root.$emit(`bv::show::modal`, `${modalId}${this.component.id}`);
            },
            ...mapMutations('expressionBuilder', [
                'SET_COMPONENT',
                'SET_DROPDOWNS',
                'SET_LEFT_HAND_COMPONENT'
            ]),
        },
    };
</script>
