import {mapMutations, mapState} from 'vuex';

import {generateId} from '@/utilities';

export default {
    computed: {
        orderedExpressionGroups() {
            // Should be implemented by Vue component.
            return [];
        },
        ...mapState('expressionBuilder', [
            'expressionGroups',
        ]),
    },
    methods: {
        addExpressionGroup($event, parentId = null) {
            this.$ga.event('Expression Group', 'Add Expression Group', 'action');
            $event.target.blur();

            let groupId = generateId(),
                expressionId = generateId();

            this.ADD_EXPRESSION_GROUP({
                id: groupId,
                operator: 'and',
                parentId,
                position: this.orderedExpressionGroups.length,
            });

            this.ADD_EXPRESSION({
                groupId,
                id: expressionId,
                operator: null,
                position: 0,
            });

            this.ADD_COMPONENT({
                data: {},
                expressionId: expressionId,
                id: generateId(),
                position: 0,
                componentType: 'object',
            });

            this.ADD_COMPONENT({
                data: {},
                expressionId: expressionId,
                id: generateId(),
                position: 1,
                componentType: 'condition',
            });
        },
        removeExpressionGroup(group) {
            this.$ga.event('Expression Group', 'Remove Expression Group', 'action');
            this.DELETE_EXPRESSION_GROUP(group.id);

            if (this.orderedExpressionGroups.length) {
                // The first expression group should always have a null operator. If the first one was deleted, clear out the
                // new first expression group's operator.
                for (let index = 0; index < this.orderedExpressionGroups.length; index++) {
                    this.SET_EXPRESSION_GROUP({
                        group: this.orderedExpressionGroups[index],
                        operator: index === 0 ? null : this.orderedExpressionGroups[index].operator,
                        position: index,
                    });
                }
            }
        },
        ...mapMutations('expressionBuilder', [
            'ADD_COMPONENT',
            'ADD_EXPRESSION',
            'ADD_EXPRESSION_GROUP',
            'DELETE_EXPRESSION_GROUP',
            'SET_EXPRESSION_GROUP',
        ]),
    },
};
