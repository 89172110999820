<template>
    <div
        class="col expression"
    >
        <div
            v-if="expression.operator && expression.position > 0"
            class="col-2"
        >
            <BRComponentDropdown
                :index="0"
                :initial-value="expression.operator"
                name="operator"
                data-cy="conditionOperator"
                :parent-id="expression.id"
                parent-type="expression"
                :read-only="readOnly"
                @dropdown-changed="setOperator"
            />
        </div>

        <div class="col-12 pl-0 d-block d-lg-flex">
            <BRExpressionComponent
                v-for="component in topLevelComponents"
                :key="component.id"
                ref="components"
                :component="component"
                :read-only="readOnly"
                @children-updated="childrenChanged(component, $event)"
                @condition-changed="conditionChanged"
                @toggle-change="$emit('toggle-change', $event)"
            />
        </div>
    </div>
</template>

<script>
    import orderBy from 'lodash/orderBy';
    import {mapMutations, mapState} from 'vuex';

    import {generateId, getId} from '@/utilities';

    import BRComponentDropdown from './BRComponentDropdown.vue';
    import BRExpressionComponent from './BRExpressionComponent.vue';

    // @group ExpressionBuilder
    export default {
        name: 'BRExpression',
        components: {
            BRComponentDropdown,
            BRExpressionComponent,
        },
        props: {
            // The expression object
            expression: {
                type: Object,
                required: true,
            },
            // Denotes if expression is currently in read-only mode
            readOnly: {
                type: Boolean,
                default: false,
            },
            condition: {
                type: Object,
                default: () => ({}),
            },
        },
        computed: {
            topLevelComponents() {
                return orderBy(this.components.filter(c => !c.parentId && c.expressionId === this.expression.id), 'position');
            },
            ...mapState('expressionBuilder', [
                'components',
            ]),
        },
        created() {
            if (this.expression.operator && this.expression.position > 0) {
                this.SET_DROPDOWNS({
                    componentId: this.expression.id,
                    dropdowns: [
                        {
                            value: this.expression.operator,
                        },
                    ],
                    type: 'expression',
                });
            }
        },
        methods: {
            compileForSave() {
                let id = getId(this.expression);

                return {
                    components: this.$refs.components?.map(c => c.compileForSave()) || [],
                    id,
                    type: id ? 'Expression' : undefined,
                    operator: this.expression.operator,
                    position: this.expression.position,
                };
            },
            childrenChanged(component, children) {
                this.SET_COMPONENT({
                    component: component,
                    children: children
                });
            },
            conditionChanged({
                component,
                subType,
            }) {
                if (!subType) {
                    return;
                }

                this.$ga.event('Expression', 'Condition Changed', 'action');
                // Determine how many components this rule needs, based on the condition type. For example, "X is required"
                // only takes 2 components, while "X is equal to Y" requires 3 components.
                let requiresFollowingObject = !['exists', 'is_blank'].includes(subType);
                if (requiresFollowingObject && this.topLevelComponents.length === 2) {
                    // The condition is at the end of the list but requires another object to compare to, create
                    // a component that comes after it.
                    this.ADD_COMPONENT({
                        expressionId: this.expression.id,
                        id: generateId(),
                        data: {},
                        position: component.position + 1,
                        componentType: '',
                    });
                } else if (!requiresFollowingObject && this.topLevelComponents.length === 3) {
                    // The condition is not at the end of the list, but is a type of condition that only requires
                    // a subject, like "<x> exists". Remove any components that come after the condition.
                    for (let followingComponent of this.topLevelComponents.filter(c => c.position > component.position)) {
                        this.DELETE_COMPONENT(followingComponent.id);
                    }
                }
            },
            setOperator({value}) {
                this.$ga.event('Expression', 'Set Operator', 'action', {value});
                this.SET_EXPRESSION({
                    expression: this.expression,
                    operator: value,
                });
            },
            ...mapMutations('expressionBuilder', [
                'ADD_COMPONENT',
                'DELETE_COMPONENT',
                'SET_COMPONENT',
                'SET_DROPDOWNS',
                'SET_EXPRESSION'
            ]),
        },
    };
</script>
