<template>
    <ValidationObserver
        ref="validationObserver"
        tag="div"
    >
        <ValidationProvider
            v-if="isSingleField"
            v-slot="{errors}"
            class="form-group"
            name="title"
            :rules="{
                required: required,
                regex: regex,
            }"
            tag="div"
        >
            <label
                :id="id"
                class="form-control-label"
            >
                {{ label }}
            </label>

            <input
                v-if="literalType === 'single'"
                v-model="literalValue"
                :data-vv-as="label"
                class="form-control"
                :disabled="readOnly"
                name="literalValue"
                :placeholder="placeholder"
                type="text"
            >

            <BFormDatepicker
                v-else-if="literalType === 'date'"
                v-model="literalValue"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :disabled="readOnly"
                locale="en"
            />

            <MentionMe
                v-else-if="literalType === 'textarea'"
                ref="mentionable"
                :keys="['{', '.']"
                :items="items"
                placement="right"
                class="mb-3"
                :map-insert="({item}) => !['ForeignKey', 'JSONField'].includes(item.type) ? `${item.value}}` : item.value"
                @apply="applyMention"
                @open="onMentionableOpen"
            >
                <BFormTextarea
                    ref="textArea"
                    v-model="literalValue"
                    class="mentionable-input"
                />
            </MentionMe>

            <ValidationError
                custom-class="literal-value-error"
                :errors="errors"
                :target="id"
                tool-tip-container="toolTipContainer"
                triggers=""
            />
        </ValidationProvider>

        <div
            v-else-if="literalType === 'list'"
            class="form-group"
        >
            <label
                id="literalValueListLabel"
                class="form-control-label"
            >
                {{ label }}s
            </label>

            <ValidationProvider
                v-for="(item, itemIndex) in literalValue"
                :key="itemIndex"
                v-slot="{errors}"
                tag="div"
                class="d-flex align-items-center mb-2"
                rules="required"
                name="literalValues"
            >
                <input
                    :id="`literalValueField${itemIndex}`"
                    ref="literalValue"
                    v-model="item.value"
                    class="form-control"
                    data-cy="literalValueField"
                    data-vv-as="Value"
                    :disabled="readOnly"
                    :name="`literalValue${itemIndex}`"
                    type="text"
                >

                <FontAwesomeIcon
                    v-if="!readOnly"
                    icon="plus-circle"
                    class="component-button add-button text-success"
                    @click="literalValue.splice(itemIndex + 1,0,{value: ''})"
                />

                <FontAwesomeIcon
                    v-if="(itemIndex >= 1 || literalValue.length > 1) && !readOnly"
                    icon="minus-circle"
                    class="component-button remove-button text-danger"
                    @click="$delete(literalValue, itemIndex)"
                />

                <ValidationError
                    custom-class="literal-value-error"
                    :errors="errors"
                    :target="`literalValueField${itemIndex}`"
                    tool-tip-container="toolTipContainer"
                    triggers=""
                />
            </ValidationProvider>

            <ValidationProvider
                v-slot="{errors}"
                tag="div"
                rules="unique"
                name="literal"
            >
                <input
                    v-model="literalValue"
                    name="literalValue"
                    type="hidden"
                >

                <ValidationError
                    custom-class="literal-value-error"
                    :errors="errors"
                    target="literalValueListLabel"
                    tool-tip-container="toolTipContainer"
                    triggers=""
                />
            </ValidationProvider>
        </div>
    </ValidationObserver>
</template>

<script>
    import MentionMe from '@imt/vue-spotme/src/components/MentionMe.vue';
    import {DEFAULT_FIELD_OPTIONS, getModelFields} from '@imt/vue-spotme/src/plugins/dot-notation';
    import ValidationError from '@imt/vue-toolbox/src/components/ValidationError.vue';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';

    import {generateId} from '@/utilities';

    // @group ExpressionBuilder
    export default {
        name: 'BRLiteralValue',
        components: {
            MentionMe,
            ValidationError,
        },
        mixins: [toastsMixin],
        props: {
            // The initial value of the literal
            initialValue: {
                type: [Array, String],
                default: '',
            },
            // The label to appear above the text input
            label: {
                type: String,
                default: 'Value',
            },
            // The type of literal (single, list, etc)
            literalType: {
                type: String,
                default: '',
            },
            // A placeholder text to put in the input
            placeholder: {
                type: String,
                default: '',
            },
            // Indicates if the literal is in read-only mode
            readOnly: {
                type: Boolean,
                default: false,
            },
            // Indicates if the field needs to be required
            required: {
                type: Boolean,
                default: true
            },
            // A regular expression to be used for validation of the literal value
            regex: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                id: generateId(),
                items: [],
                previousItem: {},
                literalValue: null,
            };
        },
        computed: {
            defaultItems() {
                return this.mapOptions(DEFAULT_FIELD_OPTIONS);
            },
            isSingleField() {
                return ['single', 'textarea', 'date'].includes(this.literalType);
            }
        },
        watch: {
            literalType(newValue) {
                if (newValue === 'list') {
                    this.literalValue = [{value: ''}];
                } else if (newValue === 'single') {
                    this.literalValue = '';
                }
            },
        },
        created() {
            this.init();
        },
        methods: {
            getModelFields,
            async applyMention({item}) {
                if (item.type === 'ForeignKey') {
                    this.items = await this.fetch(item.value) || [];
                } else {
                    this.items = [];
                }
            },
            compileForSave() {
                return {
                    type: this.literalType,
                    value: this.literalType === 'list' ? this.literalValue.map(v => v.value) : this.literalValue,
                };
            },
            async fetch(fieldName) {
                let modelFields = await this.getModelFields(fieldName.replace('_', '-')) || {};

                return this.mapOptions(modelFields);
            },
            async init() {
                this.literalValue = this.initialValue;
                this.items = this.defaultItems;

                if (this.literalType === 'list') {
                    this.literalValue = this.initialValue.map(v => ({value: v}));
                }
            },
            mapOptions(fieldOptions) {
                return Object.keys(fieldOptions).map(option => ({value: option, type: fieldOptions[option]}));
            },
            onMentionableOpen(key) {
                if (key === '{') {
                    this.items = this.defaultItems;
                }
            },
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"

    .mention-item
        border-radius: 4px
        padding: 5px 10px
        cursor: pointer

    .mention-selected
        background: rgb(192, 250, 153)
        background: $primary
        color: var(--light)

    .popover
        z-index: 9999 !important
        opacity: 1 !important
        background: var(--light)
</style>
