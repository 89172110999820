export default {
    data() {
        return {
            confirmationItem: {},
        };
    },
    methods: {
        showChildModal(modalId, payload) {
            this.confirmationItem = payload;
            // Bootstrap-Vue event. [Check their docs for full documentation](https://bootstrap-vue.js.org/)
            // @arg The id of the modal and component
            this.$root.$emit(`bv::show::modal`, modalId);
        },
    },
};
