<template>
    <div
        class="col-12 expression-group"
        :data-id="group.syncId"
        data-type="expressionGroups"
    >
        <div
            v-if="group.operator"
            class="col-2 mb-2"
        >
            <BRComponentDropdown
                :index="0"
                :initial-value="group.operator"
                data-cy="groupOperator"
                name="operator"
                :parent-id="group.id"
                parent-type="group"
                :read-only="readOnly"
                @dropdown-changed="setOperator(group, $event)"
            />
        </div>

        <div
            v-for="expression in orderedExpressions"
            :key="expression.id"
            class="expression-container"
        >
            <BRExpression
                ref="expressions"
                :expression="expression"
                :read-only="readOnly"
                :condition="condition"
                @toggle-change="$emit('toggle-change', $event)"
            />

            <div
                v-if="!readOnly"
                class="d-flex col pb-1"
            >
                <button
                    class="btn btn-outline-success action-button component-button"
                    data-cy="addConditionBtn"
                    @click="addExpression"
                >
                    <FontAwesomeIcon icon="plus-circle" /> Add Condition
                </button>

                <div v-if="orderedExpressions.length > 1">
                    <button
                        :id="`remove-condition-${expression.id}`"
                        class="btn btn-outline-danger action-button component-button"
                        data-cy="removeConditionBtn"
                    >
                        <FontAwesomeIcon icon="minus-circle" /> Remove Condition
                    </button>

                    <PopConfirm
                        :target="`remove-condition-${expression.id}`"
                        @confirmed="removeExpression(expression)"
                    />
                </div>
            </div>
        </div>

        <div
            v-for="childGroup in orderedExpressionGroups"
            :key="`group${childGroup.id}`"
            class="col-12 p-0"
        >
            <BRExpressionGroup
                ref="expressionGroups"
                :group="childGroup"
                :read-only="readOnly"
                :condition="condition"
                @toggle-change="$emit('toggle-change', $event)"
            />

            <div
                v-if="!readOnly"
                class="d-flex col pb-1"
            >
                <button
                    :id="`remove-child-group-${childGroup.id}`"
                    class="btn btn-outline-danger action-button component-button"
                >
                    <FontAwesomeIcon icon="minus-circle" /> Remove Child Group
                </button>

                <PopConfirm
                    :target="`remove-child-group-${childGroup.id}`"
                    @confirmed="removeExpressionGroup(childGroup)"
                />
            </div>
        </div>

        <div
            v-if="!readOnly"
            class="d-flex col pb-1"
        >
            <button
                class="btn btn-outline-success action-button component-button"
                @click="addExpressionGroup($event, group.id)"
            >
                <FontAwesomeIcon icon="plus-circle" /> Add Child Group
            </button>
        </div>
    </div>
</template>

<script>
    import PopConfirm from '@imt/vue-kit-car/src/components/PopConfirm.vue';
    import orderBy from 'lodash/orderBy';
    import {mapMutations, mapState} from 'vuex';

    import orderedExpressionGroupsMixin from '@/mixins/child-expression-groups';
    import RemoveItemConfirmationMixin from '@/mixins/remove-item-confirmation';
    import {generateId, getId} from '@/utilities';

    import BRComponentDropdown from './BRComponentDropdown.vue';
    import BRExpression from './BRExpression.vue';

    // @group ExpressionBuilder
    export default {
        name: 'BRExpressionGroup',
        components: {
            BRComponentDropdown,
            BRExpression,
            PopConfirm
        },
        mixins: [
            orderedExpressionGroupsMixin,
            RemoveItemConfirmationMixin,
        ],
        props: {
            // The expression group object
            group: {
                type: Object,
                required: true,
            },
            // Denotes if the component is currently in read-only mode
            readOnly: {
                type: Boolean,
                default: false,
            },
            condition: {
                type: Object,
                default: () => ({}),
            }
        },
        computed: {
            orderedExpressionGroups() {
                return orderBy(this.expressionGroups.filter(group => group.parentId === this.group.id), 'position');
            },
            orderedExpressions() {
                return orderBy(this.expressions.filter(e => e.groupId === this.group.id), 'position');
            },
            ...mapState('expressionBuilder', [
                'expressionGroups',
                'expressions',
            ]),
        },
        created() {
            if (this.group.operator) {
                this.SET_DROPDOWNS({
                    componentId: this.group.id,
                    dropdowns: [
                        {
                            value: this.group.operator
                        }
                    ],
                    type: 'group',
                });
            }
        },
        methods: {
            addExpression() {
                this.$ga.event('Expression Group', 'Add Expression', 'action');
                let lastPosition = this.orderedExpressions[this.orderedExpressions.length - 1].position,
                    expressionId = generateId();

                this.ADD_EXPRESSION({
                    groupId: this.group.id,
                    id: expressionId,
                    operator: 'and',
                    position: lastPosition + 1,
                });

                this.ADD_COMPONENT({
                    data: {},
                    expressionId,
                    id: generateId(),
                    position: 0,
                    componentType: 'object',
                });

                this.ADD_COMPONENT({
                    data: {},
                    expressionId,
                    id: generateId(),
                    position: 1,
                    componentType: 'condition',
                });
            },
            compileForSave() {
                let id = getId(this.group);

                return {
                    children: this.$refs.expressionGroups?.map(g => g.compileForSave()) || [],
                    expressions: this.$refs.expressions?.map(e => e.compileForSave()) || [],
                    id,
                    type: id ? 'ExpressionGroup' : undefined,
                    operator: this.group.operator,
                    position: this.group.position,
                };
            },
            removeExpression(expression) {
                this.$ga.event('Expression Group', 'Remove Expression', 'action');
                this.DELETE_EXPRESSION(expression.id);

                if (this.orderedExpressions.length) {
                    // The first expression should always have a null operator. If the first one was deleted, clear out the
                    // new first expression's operator.
                    for (let index = 0; index < this.orderedExpressions.length; index++) {
                        this.SET_EXPRESSION({
                            expression: this.orderedExpressions[index],
                            operator: index === 0 ? null : this.orderedExpressions[index].operator,
                            position: index,
                        });
                    }
                }
            },
            setOperator(group, {value}) {
                this.$ga.event('Expression Group', 'Set Operator', 'action', {value});
                this.SET_EXPRESSION_GROUP({
                    group,
                    operator: value,
                });
            },
        ...mapMutations('expressionBuilder', [
            'ADD_COMPONENT',
            'ADD_EXPRESSION',
            'DELETE_EXPRESSION',
            'SET_DROPDOWNS',
            'SET_EXPRESSION',
            'SET_EXPRESSION_GROUP',
        ]),
        },
    };
</script>

<style scoped lang="sass">

    .expression-group
        border: 2px var(--kc-gray-200) dashed
</style>
