<template>
    <div
        :id="`action${action.syncId || action.id}`"
        data-cy="actions"
        class="form-group rule-action"
        :data-id="action.syncId || action.id"
        data-type="actions"
    >
        <template
            v-for="(dropdown, dropdownIndex) in actionDropdowns"
        >
            <BRLiteralValue
                v-if="dropdown.props.name in valueConfig"
                :ref="valueConfig[dropdown.props.name]['ref']"
                :key="dropdown.key"
                data-cy="textInputValue"
                :use-autocomplete="useAutocomplete"
                :initial-value="getActionValue(action, valueConfig[dropdown.props.name]['saveTo'])"
                :label="valueConfig[dropdown.props.name]['label']"
                :literal-type="valueConfig[dropdown.props.name]['type']"
                :placeholder="valueConfig[dropdown.props.name]['placeholder']"
                :read-only="readOnly"
                :regex="valueConfig[dropdown.props.name]['regex']"
                :required="valueConfig[dropdown.props.name]['required']"
            />

            <BRComponentDropdown
                v-else
                :key="dropdown.key"
                :config-arguments="{actionType: action.actionType, ruleType: linkedPageData.ruleType}"
                :custom-dropdowns="actionsConfig"
                :index="dropdownIndex"
                :parent-id="action.id"
                parent-type="action"
                :read-only="readOnly"
                v-bind="dropdown.props"
                v-on="dropdown.events"
            />
        </template>

        <div
            v-if="!readOnly"
            class="d-flex mt-1"
        >
            <button
                v-if="showAddButton"
                class="btn btn-outline-success action-button"
                @click="$emit('add-action')"
            >
                <FontAwesomeIcon icon="plus-circle" />
                Add Action
            </button>

            <button
                v-show="showRemoveButton"
                :id="`remove-button-${action.id}`"
                class="btn btn-outline-danger action-button"
            >
                <FontAwesomeIcon icon="minus-circle" />
                Remove Action
            </button>

            <PopConfirm
                :target="`remove-button-${action.id}`"
                @confirmed="$emit('remove-action')"
            />
        </div>
    </div>
</template>

<script>
    import PopConfirm from '@imt/vue-kit-car/src/components/PopConfirm.vue';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils, {UUID_REGEX} from '@imt/vue-toolbox/src/utils';
    import WFEutils from '@imt/vue-web-form-editor/src/components/_utilities';
    import cloneDeep from 'lodash/cloneDeep';
    import get from 'lodash/get'; // eslint-disable-line you-dont-need-lodash-underscore/get
    import set from 'lodash/set';
    import {mapMutations, mapGetters, mapState, mapActions} from 'vuex';

    import {generateId, getId} from '@/utilities';

    import BRComponentDropdown from './BRComponentDropdown.vue';
    import BRLiteralValue from './BRLiteralValue.vue';

    // @group ExpressionBuilder
    export default {
        name: 'BRAction',
        components: {
            BRComponentDropdown,
            BRLiteralValue,
            PopConfirm,
        },
        mixins: [
            toastsMixin,
        ],
        props: {
            // Original Action object
            initialAction: {
                type: Object,
                required: true,
            },
            // Indicates if the action is shown in read-only mode
            readOnly: {
                type: Boolean,
                default: false,
            },
            // Indicates if add button should be shown
            showAddButton: {
                type: Boolean,
                default: false,
            },
            // Indicates if remove button should be shown
            showRemoveButton: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                action: {},
                valueConfig: {
                    'error_message': {
                        'label': 'Message',
                        'placeholder': 'Enter a message...',
                        'saveTo': 'error_message',
                        'ref': 'errorMessage',
                        'regex': '',
                        'required': true,
                        'type': 'textarea'
                    },
                    'component_sync_id': {
                        'label': 'Component Identifier',
                        'placeholder': 'Enter a component identifier...',
                        'saveTo': 'data.componentSyncId',
                        'ref': 'componentSyncId',
                        'regex': UUID_REGEX,
                        'required': true,
                        'type': 'single'
                    },
                    'field_value': {
                        'label': 'Value',
                        'placeholder': 'Enter a field value...',
                        'saveTo': 'data.value',
                        'ref': 'fieldValue',
                        'regex': '',
                        'required': false,
                        'type': 'single'
                    },
                },
                UUID_REGEX,
            };
        },
        computed: {
            actionDropdowns() {
                return this.dropdowns.action[this.action.id] || [];
            },
            useAutocomplete() {
                return ['screen_error', 'agent_remark'].includes(this.action.actionType);
            },
            ...mapGetters('expressionBuilder', [
                'actionsConfig',
                'childDropdownConfig',
            ]),
            ...mapState(['currentPolicySystem']),
            ...mapState('expressionBuilder', [
                'dropdowns',
                'linkedPageData',
            ]),
        },
        created() {
            this.action = cloneDeep(this.initialAction);

            try {
                Promise.all([
                    this.fetchUassists(),
                    this.fetchCompany(this.currentPolicySystem.imtCompanyId),
                ]);
            } catch (error) {
                utils.console.log(error);
                this.error(error.message, 'Error Retrieving Data:');
            }

            this.setDropdowns();
        },
        methods: {
            changeActionType({value}) {
                this.$ga.event('Action Component', 'Change Action Type', 'action', value);
                this.action.actionType = value;
                this.setDropdowns();
            },
            compileForSave() {
                let id = getId(this.action),
                    action = {
                        action_type: '',
                        data: {},
                        error_message: '',
                        id,
                        type: id ? 'Action' : undefined,
                    };

                for (let dropdown of this.actionDropdowns) {
                    let options,
                        value,
                        saveTo;

                    if (dropdown.props.name in this.valueConfig) {
                        saveTo = this.valueConfig[dropdown.props.name].saveTo;
                        value = this.$refs[this.valueConfig[dropdown.props.name].ref][0].compileForSave().value;
                    } else {
                        let dropdownConfig = this.actionsConfig[dropdown.props.name] || {};
                        saveTo = dropdownConfig.savedTo;

                        if (typeof dropdownConfig.options === 'function') {
                            options = dropdownConfig.options({actionType: this.action.actionType, excludeCode: true}).options;
                        } else {
                            // The dropdown has a static set of options.
                            options = dropdownConfig.options;
                        }

                        value = dropdown.value;
                    }

                    set(action, saveTo, value);

                    if (saveTo === 'data.code') {
                        set(action, 'data.description', options[value]);
                    }

                    if (saveTo === 'data.componentSyncId' && this.linkedPageData.ruleType === 'page_validation') {
                        set(action, 'data.page', this.linkedPageData.pageId);
                    }
                }

                return action;
            },
            getActionValue(action, saveTo) {
                return get(action, WFEutils.formatPath(saveTo));
            },
            setDropdowns() {
                let actionTypeDropdown = {
                        events: {
                            'dropdown-changed': this.changeActionType,
                        },
                        key: 'actionType',
                        props: {
                            initialValue: this.action.actionType,
                            name: 'actionType',
                        },
                        value: this.action.actionType,
                    },
                    childDropdowns = [];

                if (this.action.actionType) {
                    let config = this.childDropdownConfig[this.action.actionType],
                        dropdownNames = config;

                    if (typeof config === 'function') {
                        dropdownNames = config({linkedPageData: this.linkedPageData});
                    }

                    for (let dropdownName of dropdownNames) {
                        let savedTo = this.actionsConfig[dropdownName]?.savedTo;

                        childDropdowns.push({
                            events: {},
                            key: generateId(),
                            props: {
                                initialValue: get(this.action, savedTo, null),
                                name: dropdownName,
                            },
                        });
                    }
                }

                this.SET_DROPDOWNS({
                    componentId: this.action.id,
                    dropdowns: [actionTypeDropdown, ...childDropdowns],
                    type: 'action',
                });
            },
            ...mapActions('expressionBuilder', [
                'fetchCompany'
            ]),
            ...mapMutations('expressionBuilder', [
                'SET_DROPDOWNS'
            ]),
            ...mapActions('expressionBuilder', [
                'fetchUassists'
            ])
        },
    };
</script>
