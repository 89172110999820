<template>
    <div class="row expression-builder">
        <div
            v-for="group in orderedExpressionGroups"
            :key="group.id"
            data-cy="orderedExpressionGroup"
            class="col-12 p-0"
        >
            <BRExpressionGroup
                ref="expressionGroups"
                :group="group"
                :read-only="readOnly"
                :condition="condition"
                data-cy="expressionGroup"
                @toggle-change="$emit('toggle-change', $event)"
            />

            <div
                v-if="!readOnly"
                class="d-flex col pb-1"
            >
                <button
                    v-if="group.position === orderedExpressionGroups.length - 1"
                    class="btn btn-outline-success action-button component-button"
                    data-cy="addExpressionGroup"
                    @click="addExpressionGroup"
                >
                    <FontAwesomeIcon icon="plus-circle" />
                    Add Group
                </button>

                <div v-if="orderedExpressionGroups.length > 1">
                    <button
                        :id="`remove-group-${group.id}`"
                        data-cy="removeExpressionGroup"
                        class="btn btn-outline-danger action-button component-button"
                    >
                        <FontAwesomeIcon icon="minus-circle" />
                        Remove Group
                    </button>

                    <PopConfirm
                        :target="`remove-group-${group.id}`"
                        @confirmed="removeExpressionGroup(group)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PopConfirm from '@imt/vue-kit-car/src/components/PopConfirm.vue';
    import toolboxUtils from '@imt/vue-toolbox/src/utils';
    import orderBy from 'lodash/orderBy';
    import {mapActions, mapMutations, mapState} from 'vuex';

    import ChildExpressionGroupsMixin from '@/mixins/child-expression-groups';
    import RemoveItemConfirmationMixin from '@/mixins/remove-item-confirmation';
    import {generateId} from '@/utilities';

    import BRExpressionGroup from './BRExpressionGroup.vue';


    // @group ExpressionBuilder
    export default {
        name: 'BRExpressionBuilder',
        components: {
            BRExpressionGroup,
            PopConfirm,
        },
        mixins: [
            ChildExpressionGroupsMixin,
            RemoveItemConfirmationMixin,
        ],
        props: {
            condition: {
                type: Object,
                default: () => ({}),
            },
            isNewRule: {
                type: Boolean,
                default: false,
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            orderedExpressionGroups() {
                return this.condition ? orderBy(this.expressionGroups.filter(group => !group.parentId), 'position') : [];
            },
            ...mapState([
                'currentPolicySystem',
            ]),
            ...mapState('expressionBuilder', [
                'expressionGroups',
            ]),
        },
        async created() {
            await this.$nextTick();
            let dataType = this.$route.query.dataType,
                formNumber = this.$route.query.formNumber,
                classCode = this.$route.query.classCode;

            if (this.isNewRule) {
                let groupId = this.expressionGroups[0].id,
                    expressionId = generateId();

                if (dataType || formNumber || classCode) {
                    // Delete first expression so a new one can be added for the query params
                    this.DELETE_EXPRESSION(this.expressionGroups[0].expressions[0].id);
                }

                if (formNumber || classCode) {
                    // Create an expression to match the form number or class code
                    this.ADD_EXPRESSION({
                        groupId,
                        id: expressionId,
                        operator: null,
                        position: 0,
                    });

                    this.ADD_COMPONENT({
                        data: {
                            dataType: formNumber ? 'version.forms.form_number' : 'version.class_codes.code',
                        },
                        expressionId,
                        id: generateId(),
                        position: 0,
                        componentType: 'object',
                    });

                    this.ADD_COMPONENT({
                        data: {
                            dataType: 'equals',
                        },
                        expressionId,
                        id: generateId(),
                        position: 1,
                        componentType: 'condition',
                    });

                    this.ADD_COMPONENT({
                        data: {
                            value: formNumber || classCode
                        },
                        expressionId,
                        id: generateId(),
                        position: 2,
                        componentType: 'literal',
                    });
                }

                if (dataType) {
                    // Create an expression to match the data type
                    expressionId = generateId();

                    this.ADD_EXPRESSION({
                        groupId,
                        id: expressionId,
                        operator: null,
                        position: 0,
                    });

                    this.ADD_COMPONENT({
                        data: {
                            dataType,
                        },
                        expressionId,
                        id: generateId(),
                        position: 0,
                        componentType: 'object',
                    });

                    this.ADD_COMPONENT({
                        data: {
                            dataType: 'equals',
                        },
                        expressionId,
                        id: generateId(),
                        position: 1,
                        componentType: 'condition',
                    });

                    this.ADD_COMPONENT({
                        data: {},
                        expressionId,
                        id: generateId(),
                        position: 2,
                        componentType: 'literal',
                    });
                }
            }

            try {
                await Promise.all([
                    this.fetchClassCodes(this.currentPolicySystem.id),
                    this.fetchForms(this.currentPolicySystem.id),
                    this.fetchPages(this.currentPolicySystem.id),
                ]);
            } catch (e) {
                toolboxUtils.console.log(e);
            }
        },
        methods: {
            compileForSave() {
                let expressionGroups = [];

                for (let group of this.$refs.expressionGroups) {
                    expressionGroups.push(group.compileForSave());
                }

                return expressionGroups;
            },
            ...mapActions('expressionBuilder', [
                'fetchClassCodes',
                'fetchForms',
                'fetchPages',
            ]),
            ...mapMutations('expressionBuilder', [
                'ADD_COMPONENT',
                'ADD_EXPRESSION',
                'DELETE_EXPRESSION',
                'SET_EXPRESSION_GROUP',
            ]),
        },
    };
</script>
