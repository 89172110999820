<template>
    <ValidationObserver
        ref="validationObserver"
        tag="div"
    >
        <div
            v-if="(order.length || config.isDotNotationInput) && !isLoading"
            class="form-group"
        >
            <div class="component-dropdown-header w-100">
                <div>
                    <label
                        :id="`${dropdownId}Label`"
                        class="form-control-label"
                    >
                        {{ config.label }}
                    </label>

                    <template v-if="config.helpWording">
                        <FontAwesomeIcon
                            :id="helpWordingId"
                            icon="info-circle"
                            class="fa ml-1 kc-help-wording"
                        />

                        <BPopover
                            custom-class="kc-help-wording"
                            :target="helpWordingId"
                            :title="config.label"
                            triggers="hover"
                        >
                            <!-- eslint-disable-next-line vue/no-v-html -->
                            <div v-html="helpWording" />
                        </BPopover>
                    </template>
                </div>
            </div>

            <ValidationProvider
                v-slot="{errors}"
                tag="div"
                :rules="config.allowEmpty || displayAsText ? '' : 'required'"
                name="dropdownValue"
            >
                <PopoverSelect
                    v-if="config.isPopover"
                    :id="dropdownId"
                    v-model="internalValue.value"
                    :column-classes="config.columnClasses"
                    :data-vv-as="config.label"
                    :name="name"
                    :options="dropdownOptions"
                    :placeholder="config.placeholder"
                    :read-only="readOnly"
                    @item-selected="dropdownChanged"
                />

                <DotNotationInput
                    v-else-if="config.isDotNotationInput"
                    :id="dropdownId"
                    v-model="internalValue.value"
                    data-cy="dotNotationInput"
                    :error-target="`${dropdownId}Label`"
                    required
                    :disabled="readOnly"
                    @change="dropdownChanged({value: $event})"
                />
                <div
                    v-else-if="displayAsText"
                    class="pb-2 pt-1"
                >
                    {{ internalValue.label }}
                </div>

                <Multiselect
                    v-else
                    ref="dropdown"
                    v-model="internalValue"
                    :allow-empty="!!config.allowEmpty"
                    :data-vv-as="config.label"
                    :data-cy="name"
                    :class="config.label"
                    deselect-label=""
                    :disabled="readOnly"
                    label="label"
                    :name="name"
                    :options="dropdownOptions"
                    :placeholder="config.placeholder"
                    select-label=""
                    track-by="value"
                    @input="dropdownChanged"
                />
                <ValidationError
                    :custom-class="config.label"
                    :errors="errors"
                    :target="`${dropdownId}Label`"
                    tool-tip-container="toolTipContainer"
                    triggers=""
                />
            </ValidationProvider>
        </div>
        <div v-else-if="isLoading">
            <div class="loading">
                <div class="content text-center my-auto">
                    <FontAwesomeIcon
                        icon="spinner"
                        pulse
                    />
                    <h5>loading...</h5>
                </div>
            </div>
        </div>
        <div v-else-if="config">
            <ValidationProvider
                ref="noOptionsValidation"
                v-slot="{errors}"
                tag="div"
                rules="required"
            >
                <div
                    :id="`noOptionsMessage${dropdownId}`"
                    data-cy="noOptionsMessage"
                    class="mt-2"
                >
                    No dropdown options found for {{ config.label }}.
                </div>
                <ValidationError
                    :errors="errors"
                    :target="`noOptionsMessage${dropdownId}`"
                    tool-tip-container="toolTipContainer"
                    triggers=""
                />
            </ValidationProvider>
        </div>
    </ValidationObserver>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {faSync, faUndo} from '@fortawesome/pro-solid-svg-icons';
    import PopoverSelect from '@imt/vue-popover-select/src/components/PopoverSelect.vue';
    import DotNotationInput from '@imt/vue-spotme/src/components/DotNotationInput.vue';
    import ValidationError from '@imt/vue-toolbox/src/components/ValidationError.vue';
    import Multiselect from 'vue-multiselect';
    import {mapGetters, mapMutations, mapState} from 'vuex';

    import {dropdownUtils, generateId} from '@/utilities';

    library.add(faSync, faUndo);

    // @group ExpressionBuilder
    export default {
        name: 'BRComponentDropdown',
        components: {
            DotNotationInput,
            Multiselect,
            PopoverSelect,
            ValidationError,
        },
        props: {
            // Contains parent component, the subtype of the parent, and the previous component
            configArguments: {
                type: Object,
                default: () => ({}),
            },
            // Custom dropdown items that the component may have
            customDropdowns: {
                type: Object,
                default: null,
            },
            // The index of the dropdown within its parent container
            index: {
                type: Number,
                required: true,
            },
            // The initial value of the dropdown, if it has one
            initialValue: {
                type: String,
                default: null,
            },
            // The name of the dropdown component
            name: {
                type: String,
                required: false,
                default: '',
            },
            // whether the dropdown should display as a single element span
            displayAsText: {
                type: Boolean,
                required: false,
                default: false
            },
            // The id of the parent component or action
            parentId: {
                type: [String, Number],
                required: true,
            },
            // The type of parent object this dropdown has
            parentType: {
                type: String,
                required: true,
            },
            // The index of the dropdown within an object path, if applicable
            pathIndex: {
                type: Number,
                default: null,
            },
            // Indicates if the dropdown is in read-only mode
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                dropdownConfig: {},
                dropdownId: generateId(),
                helpWordingId: generateId('popover'),
                internalValue: null,
                isLoading: false,
                options: {},
                order: [],
                previousPathPartValue: null,
            };
        },
        computed: {
            config() {
                let dropdownConfig = this.customDropdowns ? this.customDropdowns : this.dropdownsConfig;

                return dropdownConfig[this.name] || {};
            },
            defaultInternalValue() {
                if (this.config.isPopover) {
                    return {value: null};
                } else if (this.config.isDotNotationInput) {
                    return {value: ''};
                }

                return null;
            },
            dropdownOptions() {
                return this.order.map(value => {
                    return {
                        label: this.options[value],
                        value,
                    };
                });
            },
            hasMultipleOptions() {
                return this.order.length > 1;
            },
            helpWording() {
                if (typeof this.config.helpWording === 'function') {
                    return this.config.helpWording({order: this.order});
                } else {
                    return this.config.helpWording;
                }
            },
            leftHandDropdownValues() {
                // Some dropdowns in the right-hand component need to know if the left-hand values changed.
                if (this.name !== 'conditionType' && !(this.configArguments.isRightHand && [
                    'literalType',
                ].includes(this.name))) {
                    return [];
                }

                return this.dropdowns.component[this.configArguments.leftHandComponents[this.configArguments.expressionId]].map(d => d.value);
            },
            previousDropdownValue() {
                if (this.index > 1) {
                    return this.dropdowns[this.parentType]?.[this.parentId]?.[this.index - 1]?.value;
                }

                // The component type dropdown has no previous dropdowns, and the secondary dropdown will always be
                // re-mounted whenever the component type changes, so there is no need for them to watch for changes.
                return null;
            },
            value() {
                if (this.internalValue) {
                    return this.internalValue.value;
                }

                return null;
            },
            ...mapGetters('expressionBuilder', [
                'dropdownsConfig',
            ]),
            ...mapState('expressionBuilder', [
                'dropdowns',
                'linkedPageData',
            ]),
        },
        watch: {
            'config.options'() {
                this.loadOptions();
            },
            isLoading(newValue) {
                this.SET_DROPDOWN_LOADING({
                    id: this.dropdownId,
                    value: newValue,
                });
            },
            leftHandDropdownValues(newValue) {
                if (newValue && newValue.length) {
                    this.loadOptions();
                }
            },
            linkedPageData() {
                if (['actionType', 'componentType'].includes(this.name)) {
                    // If a page validation or page display rule changes its rule type, page type, or page id, refresh the
                    // available options for some relevant dropdowns.
                    this.loadOptions();
                }
            },
            previousDropdownValue(newValue) {
                if (newValue) {
                    this.loadOptions();
                }
            },
            value(newValue) {
                this.SET_DROPDOWN_VALUE({
                    index: this.index,
                    parentId: this.parentId,
                    parentType: this.parentType,
                    value: newValue,
                });
            },
        },
        created() {
            this.internalValue = this.defaultInternalValue;
            this.loadOptions();
        },
        destroyed() {
            this.SET_DROPDOWN_LOADING({
                id: this.dropdownId,
                value: false,
            });
        },
        methods: {
            dropdownChanged(option) {
                // Notify the parent component when a dropdown is changed.
                this.$emit('dropdown-changed', {
                    pathIndex: this.pathIndex,
                    name: this.name,
                    value: option ? option.value : null,
                });
            },
            async loadOptions() {
                if (typeof this.config.options === 'function') {
                    // The dropdown needs to fetch its options from an API call, or determine them programmatically.
                    this.isLoading = true;
                    let {
                        options,
                        order,
                    } = await this.config.options({
                        ...this.configArguments,
                        leftHandValues: this.leftHandDropdownValues,
                        name: this.name,
                        previousDropdownValue: this.previousDropdownValue,
                    });

                    this.isLoading = false;
                    this.options = options;
                    this.order = order || Object.keys(this.options).sort();
                } else {
                    // The dropdown has a static set of options.
                    this.options = this.config.options;
                    this.order = this.config.order || Object.keys(this.options).sort();
                }

                this.setDropdownValue();
            },
            setDropdownValue() {
                let value = this.value;
                if (!value) {
                    value = this.initialValue !== null ? this.initialValue :
                        this.config.default !== undefined ? this.config.default :
                        null;
                }

                if (this.config.isDotNotationInput) {
                    this.internalValue = value ? {value} : this.defaultInternalValue;
                } else if (!this.hasMultipleOptions && !this.config.allowEmpty) {
                    // If there is only one option available, and no value has been selected or the previous value is no longer valid, select the only option in the list?
                    if (this.order.length && (value === null || !Object.prototype.hasOwnProperty.call(this.options, value))) {
                        value = this.order[0];
                    }

                    this.internalValue = dropdownUtils.findOptions(value, this.dropdownOptions)[0] || this.defaultInternalValue;
                } else {
                    // Set the default value of the dropdown.
                    if (value !== null && Object.prototype.hasOwnProperty.call(this.options, value)) {
                        this.internalValue = dropdownUtils.findOptions(value, this.dropdownOptions)[0];
                    } else {
                        this.internalValue = this.defaultInternalValue;
                    }
                }

                this.dropdownChanged(this.internalValue);
            },
            ...mapMutations('expressionBuilder', [
                'SET_DROPDOWN_LOADING',
                'SET_DROPDOWN_VALUE'
            ]),
        },
    };
</script>
